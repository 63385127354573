@font-face {
	font-family: 'Normalidad Variable';
	src: url('/fonts/normalidad/Normalidad-Variable-Full-Variable-Web.eot');
	src:
	     url('/fonts/normalidad/Normalidad-Variable-Full-Variable-Web.woff2') format('woff2'),
	     url('/fonts/normalidad/Normalidad-Variable-Full-Variable-Web.woff') format('woff'),
			 url('/fonts/normalidad/Normalidad-Variable-Full-Variable-Web.ttf') format('truetype');
	font-display: swap;
	font-weight: normal;
	font-style: normal;
}
