@import "~normalize-scss";
@import "./normalidad.scss";
@include normalize();

* {
  font-family: 'Normalidad Variable', sans-serif;
  font-weight: normal;
  font-style: normal;
  box-sizing: border-box;

  &::after {
    box-sizing: inherit;
  }
  &::before {
    box-sizing: inherit;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.reset [type="number"] {
  -moz-appearance: spinner-textfield;
}

.reset input[type='number']::-webkit-outer-spin-button,
.reset input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
}

.menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background-color: transparent;
  padding-left: 150px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  a {
    text-decoration: none;
    color: #666;
    margin-right: 20px;
    position: relative;
    font-variation-settings: "wght" 450, "wdth" 150;

    &:last-child {
      margin-right: 0;
    }

    &::after {
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: rgba(130, 205, 250, .3);
      content: '';
    }

    &:hover {
      color: #ef5b5f;
      &::after {
        background-color: rgba(130, 205, 250, 1);
      }
    }
  }

  @media(max-width: 1400px) {
    padding: 0 90px;
  }

  @media(max-width: 1000px) {
    padding: 0 60px;
  }
  @media(max-width: 660px) {
    height: 100px;
    padding: 0;
    justify-content: center;
  }
}

.menu-about {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0 0;

  a {
    text-decoration: none;
    color: #666;
    position: relative;
    font-variation-settings: "wght" 450, "wdth" 150;

    &:last-child {
      margin-right: 0;
    }

    &::after {
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: rgba(130, 205, 250, .3);
      content: '';
    }

    &:hover {
      color: #ef5b5f;
      &::after {
        background-color: rgba(130, 205, 250, 1);
      }
    }
  }
}

.cover {
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media(max-width: 660px) {
    grid-template-columns: 1fr;
  }

  &__wrapper {
    position: relative;
    // padding: 60px;
    // grid-column-start: span 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
    padding: 0 120px;

    @media(max-width: 1400px) {
      align-items: center;
      padding: 0 60px;
    }

    @media(max-width: 1000px) {
      align-items: center;
      padding: 0 30px;
    }
    @media(max-width: 660px) {
      padding: 60px 0 0;
    }
    @media(max-width: 400px) {
      align-items: center;
      padding: 60px 0 0;
    }

    h1 {
    padding: 0;
    margin: 0;
    font-size: 70px;
    color: #333;
    font-weight: normal;
    font-variation-settings: "wght" 300, "wdth" 0;
    // text-shadow: 2px 2px 0px #fff, 3px 3px 0px #fff;
    line-height: 70px;
    span {
      color: #EC4452;
      font-variation-settings: "wght" 600, "wdth" 300;
      text-shadow: 2px 2px 0px #2385b9, 3px 3px 0px #2385b9, 4px 4px 0px #2385b9;
    }

    @media(max-width: 1200px) {
      font-size: 50px;
      line-height: 60px;
    }
    @media(max-width: 1000px) {
      font-size: 40px;
      line-height: 50px;
      text-align: left;
    }
    @media(max-width: 660px) {
      font-size: 40px;
      line-height: 50px;
      text-align: center;
    }
    }
  }

  &__content {
    padding: 120px 30px 0;
    width: 100%;

    @media(max-width: 660px) {
      width: 100%;
      padding: 30px;
    }
  }
  &__sponsor {
    display: grid;
    align-items: center;
    justify-content: center;
  }

  &__sponsors {
    flex-grow: 1;
    // display: flex;
    // align-items: center;
    // justify-content: flex-start;
    margin-top: 40px;
    padding: 10px 0 30px;
    border-top: 3px solid #f5f5f5;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    max-width: 500px;
    
    img {
      // flex-shrink: 0;
      max-width: 150px;
      // filter: grayscale(100%);

      &:hover {
        filter: none;
        opacity: .6;
      }
    }



    @media(max-width: 900px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      img {
        width: 100%;
      }
    }

    @media(max-width: 500px) {
      padding: 30px 0 15px;
      grid-template-columns: 1fr;
    }
  }
  &__sd {
    opacity: 1;
    margin-right: 10px;
    @media(max-width: 660px) {
      margin: 0;
    }
  }
  &__comf {
    opacity: .8;
  }
}

.container {
  margin: 0 auto;
  max-width: 1400px;
}


.book-container {
  // border-radius: 8px;
  // background-color: #aabdbc;
  // background-image: url(/images/bg/gray.jpg);
  background-color: #EC4452;
  // background-color: #bb9dbd;
  // background: radial-gradient(circle,#fff 0%, #fff 35%, #eee 100%);
  // background: radial-gradient(circle,#e56768 0%, #e56768 45%, #EC4452 100%);
  background-image: url(/images/bg/red.jpg);
  background-size: 50%;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  perspective: 1200px;

  @media(max-width: 660px) {
    grid-column-start: span 6;
    padding: 50px 0;
  }
}

.buttons {
  display: flex;
  margin-top: 50px;

  @media(max-width: 660px) {
    margin-top: 20px;
    flex-direction: column;
  }
}

.button {
  text-decoration: none;
  padding: 15px 20px;
  margin-right: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  font-family: 'Normalidad Variable', sans-serif;
  text-transform: uppercase;
  border: none;
  background-color: transparent;
  border: 2px solid #fff;
  background-color: #fff;
  color: #333;
  font-weight: normal;
  font-variation-settings: "wght" 700, "wdth" 300;
  font-size: 18px;
  letter-spacing: .3px;
  cursor: pointer;
  transition: transform .15s ease-in-out;
  z-index: 1;
  border-radius: 8px;

  span {
    text-transform: none;
    margin-top: 5px;
    font-variation-settings: "wght" 500, "wdth" 60;
    font-size: 12px;
    opacity: .7;
    letter-spacing: .3px;
  }

  @media(max-width: 660px) {
    align-items: center;
  }

  &:hover {
    // transform: scale(1.1,1.1);
    background-color: #f0f0f0;
    border-color: #f0f0f0;
  }

  &--cta {
    background-color: #EC4452;
    border-color: #EC4452;
    color: #fff;
    // box-shadow: #fff 4px 4px 0;
    // box-shadow: rgba(236, 68, 82, 0.07) 0px 1px 2px, rgba(236, 68, 82, 0.07) 0px 2px 4px, rgba(236, 68, 82, 0.07) 0px 4px 8px, rgba(236, 68, 82, 0.07) 0px 8px 16px, rgba(236, 68, 82, 0.07) 0px 16px 32px, rgba(236, 68, 82, 0.07) 0px 32px 64px;

    // &::before {
    //   background-color: #EC4452;
    //   border-color: #DC5257;
    //   border-width: 3px 3px 5px 5px;
    //   border-radius:4% 95% 6% 95%/95% 4% 92% 5%;
    // }
    &:hover {
      // transform: scale(1.1,1.1);
      background-color: #dd0733;
      border-color: #dd0733;
    }
  }

  &:focus{
    outline: none;
  }

  &:focus-visible {
    outline: none;
    border-color: #fff;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }



  @media(max-width: 600px) {
    background-color: #f0f0f0;
    border-color: #f0f0f0;
    margin-top: 10px;
    margin-right: 0;
    padding: 10px 5px;
    font-size: 16px;
    span {
      font-size: 11px;
    }

    &--cta {
      background-color: #EC4452;
      border-color: #EC4452;
    }
  }
}

.threedbook__wrapper {
  width: 100%;
  padding: 50px 0 70px;
  // height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  perspective: 1200px;
  background: #eee;
  background: radial-gradient(circle,#eee 0%, #eee 10%, #fff 100%);
  text-align: center;
}

.section-title {
  font-variation-settings: "wght" 500, "wdth" 0;
  font-size: 30px;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.section-subtitle {
  font-variation-settings: "wght" 300, "wdth" 0;
  font-size: 16px;
  color: #666;
  margin-bottom: 80px;
  text-align: center;
}

.donation-amount {
  // margin-top: 60px;
  // width: 100%;
  max-width: 310px;
  width: 100%;

  // background-color: #fff;
  border-radius: 8px;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

  &__wrapper {
    padding: 10px 0;
    // border: 2px solid #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    // &:focus-within {
      // border-color:#f00;
    // }
  }

  &__edit {
    cursor: pointer;
    line-height: 0;
    padding: 20px;
    border: none;
    background-color: transparent;
    touch-action: manipulation;

    &:focus {
      outline: none;
    }
    &:focus-visible {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    }

    svg {
      width: 22px;
      fill: #999;
    }
    g {
      fill: #999;
    }

    &:hover {
      svg {
        fill: #70d1fc;
      }
      g {
        fill: #70d1fc;
      }
    }

    &.disabled {
      opacity: .3;
      cursor: not-allowed;
      &:hover {
        svg {
          fill: #999;
        }
        g {
          fill: #999;
        }
      }
    }
  }


  &__button-wrapper{
    padding: 6px;
  }

  &__amount {
    color: #333;
    font-family: 'Normalidad Variable', sans-serif;
    font-size: 40px;
    font-weight: normal;
    font-variation-settings: "wght" 600, "wdth" 100;
    flex-shrink: 0;
    width: 180px;
    user-select: none;
    cursor: text;
  }

  input {
    display: none;
    color: #333;
    font-family: 'Normalidad Variable', sans-serif;
    font-size: 40px;
    font-weight: normal;
    font-variation-settings: "wght" 600, "wdth" 100;
    flex-shrink: 0;
    width: 180px;
    cursor: text;
    border: none;
    text-align: center;
    background-color: transparent;

    &:focus {
      outline: none;
    }
  }

  &--editable {
    .donation-amount__amount {
      display: none;
    }
    input {
      display: block;
    }
  }
}



.raised {
  margin-bottom: 15px;
  // border: 1px solid red;
  padding: 15px;
  &__label {
    color: #999;
    text-transform: uppercase;
    font-variation-settings: "wght" 600, "wdth" 0;
    letter-spacing: 1px;
    font-size: 12px;
    margin-top: 5px;
  }
  &__amount {
    color: #2385b9;
    font-size: 30px;
    font-variation-settings: "wght" 600, "wdth" 100;
  }
}
.progress {
  &__wrapper {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
    padding: 0 30px;
    position: relative;
    @media(max-width: 600px) {
      padding: 0 15px;
    }
  }
  &__bar {
    width: 100%;
    height: 40px;
    position: relative;
    // height: 12px;
    background-color: #fff;
    background-image: linear-gradient(120deg, #fdfbfb 0%, #eee 100%);
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    // background-image: linear-gradient(deg, #fdfbfb 0%, #ebedee 100%);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px 0px inset;
    border-radius: 8px;
    margin: 0 auto 10px;
    // overflow: hidden;
  }
  &__progress {
    transition: all .4s ease-in-out;
    padding-right: 15px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    min-width: 1%;
    width: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #84cdfa;
    background-image: linear-gradient(to right, #4facfe 0%, #84cdfa 100%);
    border-right: 2px solid #fff;
    font-variation-settings: "wght" 500, "wdth" 60;
    font-size: 14px;
    line-height: 12px;
    border-radius: 8px 0 0 8px;
  }

  &__milestone {
    position: absolute;
    bottom: -40px;
    left: 0;
    height: 40px;
    width: 100px;
    color: #9f9f9f;
    font-variation-settings: "wght" 500, "wdth" 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      position: absolute;
      left: 50%;
      height: 50px;
      top: -45px;
      width: 2px;
      background-color:#e0e0e0;
      opacity: .6;
      content: '';
    }



    @media(max-width: 600px) {
      display: none;
    }

    &--mobile{
      display: none;
      @media(max-width: 600px) {
        display: flex;
      }
    }


  }
}



.threedbook {
  transform-style: preserve-3d;
  position: relative;
  height: 290px;
  cursor: pointer;
  backface-visibility: visible;
  margin-top: 80px;
}

.front, .back, .page1, .page2, .page3, .page4, .page5, .page6 {
  transform-style: preserve-3d;
  position: absolute;
  width: 200px;
  height: 100%;
  top: 0; left: 0;
  transform-origin: left center;
  transition: transform .5s ease-in-out, box-shadow .35s ease-in-out;
  transition-delay: .35s;
  will-change: transform;
}

.front {
  background: #70d1fc;
  background-image: url(/images/comics/cover_small.jpg);
  background-size: cover;
  border-left: 3px solid #EC4452;
  // backface-visibility: hidden;

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotateY( 180deg );
    backface-visibility: hidden;
    transition: transform .5s ease-in-out, box-shadow .35s ease-in-out;
    transition-delay: .35s;
    border-bottom-left-radius: .5em;
    border-top-left-radius: .5em;
    background-color: #5fa7ce;
    // background-image: url(/images/comics/back.jpg);
    // background-size: cover;
    // background-position: center center;
  }
}

.back {
  background-color: #5fa7ce;
  // background-image: url(/images/comics/back.jpg);
  // background-size: cover;
  // background-position: center center;
}

.front, .page1, .page3, .page5 {
  border-bottom-right-radius: .5em;
  border-top-right-radius: .5em;
}

.back, .page2, .page4, .page6 {
  border-bottom-right-radius: .5em;
  border-top-right-radius: .5em;
}

.page1 { 
  background: #efefef;
  background-image: url(/images/comics/knight.jpg);
  background-size: cover;
  background-position: center center;
}

.page2 {
  background: #efefef;
  background-image: url(/images/comics/super.jpg);
  background-size: cover;
  background-position: center center;
}

.page3 {
  background: #f5f5f5;
  background-image: url(/images/comics/band.jpg);
  background-size: cover;
  background-position: center center;
}

.page4 {
  background: #f5f5f5;
  background-image: url(/images/comics/snd.jpg);
  background-size: cover;
  background-position: center center;
}

.page5 {
  background: #fafafa;
  background-image: url(/images/comics/isk1.jpg);
  background-size: cover;
  background-position: center center;
  border-left: 1px solid #eee;
}

.page6 {
  background: #fdfdfd;
  background-image: url(/images/comics/isk2.jpg);
  background-size: cover;
  background-position: center center;
  border-right: 1px solid #e0e0e0;
}


.opened .front, .threedbook:hover .front {
  transform: rotateY(-160deg) scale(1.1);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.opened .page1, .threedbook:hover .page1 {
  transform: rotateY(-150deg) scale(1.1);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.opened .page2, .threedbook:hover .page2 {
  transform: rotateY(-30deg) scale(1.1);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.opened .page3, .threedbook:hover .page3 {
  transform: rotateY(-140deg) scale(1.1);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.opened .page4, .threedbook:hover .page4 {
  transform: rotateY(-40deg) scale(1.1);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.opened .page5, .threedbook:hover .page5 {
  transform: rotateY(-130deg) scale(1.1);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.opened .page6, .threedbook:hover .page6 {
  transform: rotateY(-50deg) scale(1.1);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.opened .back, .threedbook:hover .back {
  transform: rotateY(-20deg) scale(1.1);
}

@media(max-width: 700px) {
  .threedbook__wrapper {
    perspective: 1000px;
  }

  .front {
    border-left: 1px solid red;

    &::after {
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
    }
  }
  
  .threedbook {
    height: 170px;
  } 
  
  .front, .back, .page1, .page2, .page3, .page4, .page5, .page6 {
    width: 121px;
  }

  .front, .page1, .page3, .page5 {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }
  
  .back, .page2, .page4, .page6 {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }
}

// BIG BOOK

.cont .bk {
  position: relative;
  display: block;
  width: 400px;
  height: 550px;
  border-radius: 2px 10px 10px 2px;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  // box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
  transform-style: preserve-3d;
  transition: transform .5s;

  animation-name: rotate-book;
  @media(max-width: 600px) {
    animation-name: rotate-book-mobile;
  }

  animation-duration: 6s;
  animation-timing-function: cubic-bezier(0.79,0.14,0.15,0.86);
  animation-iteration-count: infinite;

  @keyframes rotate-book {
    from {
      transform: rotate3d(0,0,0,0);
    }
    50% {
      transform: rotate3d(1,1,0,30deg);
    }
    to {
      transform: rotate3d(0,0,0,0);
    }
  }
  @keyframes rotate-book-mobile {
    from {
      transform: rotate3d(0,0,0,0);
    }
    50% {
      transform: rotate3d(.5,1,0,30deg);
    }
    to {
      transform: rotate3d(0,0,0,0);
    }
  }
}

// .cont .bk:hover {
//   // -webkit-transform: rotate3d(0,1,0,35deg);
//   // -moz-transform: rotate3d(0,1,0,35deg);
//   transform: rotate3d(0,1,0,35deg);
//   animation: none;
// }

.cont .bk > div,
.cont .fr > div {
  display: block;
  position: absolute;
}


.cont .fr {
  transform-style: preserve-3d;
  transform-origin: 0% 50%;
  transition: transform .5s;
  transform: translate3d(0,0,10px);
  z-index: 10;
}

.cont .fr > div {
  width: 400px;
  height: 550px;
}

.cont .left-side{
  width: 20px;
  left: -10px;
  height: 550px;
  background-color: #ff2c4d;
  -webkit-transform: rotate3d(0,1,0,-90deg);
  -moz-transform: rotate3d(0,1,0,-90deg);
  transform: rotate3d(0,1,0,-90deg);
}

.cont .fr > div {
  border-radius: 0 10px 10px 0;
  box-shadow: 
    inset 4px 0 10px rgba(0, 0, 0, 0.1);
}

.cont .front:after {
  content: '';
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: -1px;
  width: 1px;
}

.cont .cov:after {
  content: '';
  position: absolute;
  top: 0;
  left: 10px;
  bottom: 0;
  width: 3px;
  background: rgba(0,0,0,0.1);
  // box-shadow: 1px 0 3px rgba(255, 255, 255, 0.1);

  // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.cov {
//  background: linear-gradient(45deg,  #DAD5DC 0%, #f2ebf4 100%);
 background-color:  #70d1fc;
 background-image: url(/images/cover.jpg);
 background-size: cover;
}



@media(max-width: 1100px) {
  .cont .bk {
    width: 320px;
    height: 440px;
  }

  .cont .fr > div {
    width: 320px;
    height: 440px;
  }

  .cont .left-side{
    width: 20px;
    left: -10px;
    height: 440px;
  }
}
@media(max-width: 900px) {
  .cont .bk {
    width: 256px;
    height: 352px;
  }

  .cont .fr > div {
    width: 256px;
    height: 352px;
  }

  .cont .left-side{
    width: 20px;
    left: -10px;
    height: 352px;
  }
}

@media(max-width: 700px) {
  .cont .bk {
    width: 204px;
    height: 282px;
  }

  .cont .fr > div {
    width: 204px;
    height: 282px;
  }

  .cont .left-side{
    width: 10px;
    left: -5px;
    height: 282px;
  }

  .cont .cov:after {
    content: '';
    position: absolute;
    top: 0;
    left: 1px;
    bottom: 0;
    width: 10px;
    background: rgba(0,0,0,0.1);
    // box-shadow: 1px 0 3px rgba(255, 255, 255, 0.1);
  
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
}
@media(max-width: 370px) {
  .cont .bk {
    width: 163px;
    height: 226px;
  }

  .cont .fr > div {
    width: 163px;
    height: 226px;
  }

  .cont .left-side{
    width: 10px;
    left: -5px;
    height: 226px;
  }

  .cont .cov:after {
    content: '';
    position: absolute;
    top: 0;
    left: 1px;
    bottom: 0;
    width: 10px;
    background: rgba(0,0,0,0.1);
    // box-shadow: 1px 0 3px rgba(255, 255, 255, 0.1);
  
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
}
.costumes {
  background-color: #fff;
  padding: 70px 0 150px;
  overflow: hidden;
  position: relative;

  @media(max-width: 600px) {
    padding: 50px 0;
  }

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    content: '';
    background-image: url(/images/children/left.jpg);
    background-size: contain;
    background-repeat: no-repeat;

    @media(max-width:1800px) {
      left: -3%;
    }

    @media(max-width:1500px) {
      left: -15%;
      background-image: url(/images/children/left_small.jpg);
    }
    @media(max-width: 1000px) {
      left: -18%;
    }

    @media(max-width: 600px) {
      display: none;
    }
  }
  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 2%;
    top: 0;
    z-index: 1;
    content: '';
    background-image: url(/images/children/right.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;
    @media(max-width:1800px) {
      left: 3%;
    }
    @media(max-width:1500px) {
      left: 17%;
      background-image: url(/images/children/right_small.jpg);
    }
    @media(max-width: 1000px) {
      left: 22%;
    }
    @media(max-width: 600px) {
      display: none;
    }
  }
}

.pictures {
  background-color: #7fcdf9;
  background-image: url(/images/bg/darkblue.jpg);
  background-size: 40%;
  padding: 70px 0;
  overflow: hidden;

  @media(max-width: 600px) {
    padding: 0 0 50px;
  }
}
.pictures-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  img {
    width: 100%;
  }
  &__title {
    color: #fff;
    text-align: center;
    // color: #EC4452;
    font-size: 50px;
    font-variation-settings: "wght" 600, "wdth" 300;
    text-shadow: 1px 1px 0px #EC4452, 2px 2px 0px #EC4452, 3px 3px 0px #EC4452;
    @media(max-width: 600px) {
      margin-top: 30px;
      font-size: 35px;
      text-shadow: 1px 1px 0px #EC4452, 2px 2px 0px #EC4452;
    }
  }
  &__lead {
    max-width: 600px;
    margin: 15px auto 0;
    padding: 0 30px;
    text-align: center;
    // color: #EC4452;
    font-size: 20px;
    font-variation-settings: "wght" 300, "wdth" 0;
    color: #1b5b7e;
    line-height: 1.5em;
  }

  @media(max-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    transform: translateX(-3%);
    text-align: center;
    img {
      width: 120%;
    }

    &__one {
      grid-column-start: span 2;
      grid-row-start: 3;
      
    }
    &__two {
      grid-column-start: span 2;
      grid-row-start: 3;
      transform: translateY(-10%);
    }
    &__three {
      grid-column: 3 / span 2;
      grid-row: 2;
      width: 130% !important;
      
    }
    &__four {
      grid-column: 1 / span 2;
      grid-row: 2;
      transform: rotateZ(-5deg) translateY(5%);
    }
    &__five {
      grid-column-start: span 2;
    }
    &__six {
      grid-column-start: span 3;
      grid-row-start: 1;
      width: 80% !important;
      margin: 0 auto;
      transform: translateY(20%) translateX(10%);
    }
    &__seven {
      grid-column-start: span 2;
    }
    &__eight {
      grid-column-start: span 3;
      width: 90% !important;
      grid-row-start: 1;
      transform: translateY(10%);
    }
  }
}



.photo-grid {
  flex-grow: 1;
  flex-shrink: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  // grid-template-rows: 250px 250px;
  position: relative;
  z-index: 3;
  
  grid-gap: 15px;

  img {
    border-radius: 4px;
    display: block;
    margin: 0 auto;
    box-shadow: rgba(0,0,0, .05) 0 0 0 1px,rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    // box-shadow:rgba(0,0,0, .05) 0 0 0 1px, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  &__vert {
    width: 60%;
    border: 15px solid #fff;
    border-bottom: 40px solid #fff;
    @media(max-width: 1000px) {
      border: 10px solid #fff;
      border-bottom: 30px solid #fff;
    }
    @media(max-width: 600px) {
      width: 75%;
      border: 10px solid #fff;
      border-bottom: 30px solid #fff;
    }
  }
  &__hor {
    width: 90%;
    border: 15px solid #fff;
    border-right: 40px solid #fff;
    @media(max-width: 1000px) {
      border: 10px solid #fff;
      border-right: 30px solid #fff;
    }
    @media(max-width: 600px) {
      width: 110%;
      border: 10px solid #fff;
      border-right: 30px solid #fff;
    }
  }

  &__one {
    img {
      transform: translateX(60%) translateY(40%) rotateZ(-10deg);
    }
  }

  &__two {
    img {
      transform: translateX(30%) translateY(20%) rotateZ(5deg);
    }
  }

  &__three {
    img {
      transform: translateX(-30%) translateY(20%) rotateZ(-10deg);
    }
  }

  &__four {
    img {
      transform: translateX(-120%) translateY(25%) rotateZ(10deg);
    }
  }

  &__five {
    img {
      transform: translateX(100%) translateY(-20%) rotateZ(10deg);
    }
  }

  &__six {
    img {
      transform: translateX(30%) translateY(10%) rotateZ(-10deg);
    }
  }

  &__seven {
    img {
      transform: translateX(-20%) translateY(20%) rotateZ(20deg);
    }
  }

  &__eight {
    img {
      transform: translateX(-80%) translateY(-10%) rotateZ(-5deg);
    }
  }
 
  &__title {
    color: #EC4452;
    text-align: center;
    // color: #EC4452;
    font-size: 50px;
    font-variation-settings: "wght" 600, "wdth" 300;
    text-shadow: 1px 1px 0px #2385b9, 2px 2px 0px #2385b9, 3px 3px 0px #2385b9;
    position: relative;
    z-index: 3;
    @media(max-width: 600px) {
      font-size: 35px;
      text-shadow: 1px 1px 0px #2385b9, 2px 2px 0px #2385b9;
    }
  }
  &__lead {
    max-width: 600px;
    margin: 15px auto 0;
    padding: 0 30px;
    text-align: center;
    // color: #EC4452;
    font-size: 20px;
    font-variation-settings: "wght" 300, "wdth" 0;
    color: #1b5b7e;
    position: relative;
    z-index: 3;
    line-height: 1.5em;
  }

  @media(max-width: 600px) {
    grid-template-columns: 1fr 1fr;
    grid-gap:0;


    &__one {
      img {
        transform: translateX(20%) translateY(40%) rotateZ(-10deg);
      }
      position:relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -20%;
        left: 50%;
        width: 35%;
        height: 30%;
        background-image: url(/images/children/star.jpg);
        // background-color: #f00;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
      }
    }
  
    &__two {
      img {
        transform: translateX(-30%) translateY(20%) rotateZ(5deg);
      }
      position:relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -80%;
        left: 40%;
        width: 50%;
        height: 50%;
        background-image: url(/images/children/two_stars.jpg);
        // background-color: #f00;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
      }
    }
  
    &__three {
      img {
        transform: translateX(-30%) translateY(20%) rotateZ(-10deg);
      }
      position:relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -110%;
        left: 20%;
        width: 70%;
        height: 80%;
        background-image: url(/images/children/heart_sun.jpg);
        // background-color: #f00;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
      }
    }
  
    &__four {
      img {
        transform: translateX(-100%) translateY(25%) rotateZ(10deg);
      }
    }
  
    &__five {
      img {
        transform: translateX(100%) translateY(-40%) rotateZ(-10deg);
      }
    }
  
    &__six {
      img {
        transform: translateX(35%) translateY(-10%) rotateZ(10deg);
      }
      position:relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -80%;
        left: 50%;
        width: 50%;
        height: 60%;
        background-image: url(/images/children/dyno.jpg);
        // background-color: #f00;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
      }
    }
  
    &__seven {
      img {
        transform: translateX(-20%) translateY(20%) rotateZ(20deg);
      }
    }
  
    &__eight {
      img {
        transform: translateX(-60%) translateY(0%) rotateZ(-5deg);
      }
    }
  }
}
.comics {
  background-color: #e56768;
  background-image: url(/images/bg/red.jpg);
  background-size: 30%;
  padding: 70px 0 0;
  overflow: hidden;

  @media(max-width: 600px) {
    padding: 50px 0 0;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: -10%;

    @media(max-width: 600px){
      transition: all 2s ease-in-out;
      width: 200%;
      transform: translateX(-25%);
    }
  }

  &__page {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: rgba(0,0,0, .05) 0 0 0 1px,rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-shadow: rgba(0,0,0, .05) 0 0 0 1px, rgb(38, 57, 77) 0px 20px 30px -10px;
    position: relative;
    transition: all 2s ease-in-out;
    img {
      width: 100%;
    }
  }

  &__first {
    transform: rotateZ(-15deg) translateY(20%) translateX(20%);
  }
  &__second {
    z-index: 3;
  
    transform: rotateZ(-2.5deg) translateY(0) translateX(0);
  }
  &__third {
    transform: rotateZ(15deg) translateY(20%) translateX(-20%);
  }

  &__title {
    color: #fff;
    text-align: center;
    // color: #EC4452;
    font-size: 50px;
    font-variation-settings: "wght" 600, "wdth" 300;
    text-shadow: 1px 1px 0px #2385b9, 2px 2px 0px #2385b9, 3px 3px 0px #2385b9;
    @media(max-width: 600px) {
      font-size: 35px;
      text-shadow: 1px 1px 0px #2385b9, 2px 2px 0px #2385b9;
    }
  }
  &__lead {
    max-width: 600px;
    margin: 15px auto 0;
    margin-bottom: 60px;
    padding: 0 30px;
    text-align: center;
    // color: #EC4452;
    font-size: 20px;
    font-variation-settings: "wght" 300, "wdth" 0;
    color: #fff;
    line-height: 1.5em;
    text-shadow: 1px 1px 0px #af4548, 2px 2px 0px #af4548;
  }
}

.goal {
  padding: 50px 0 70px;

  &__title {
    color: #EC4452;
    text-align: center;
    // color: #EC4452;
    font-size: 50px;
    font-variation-settings: "wght" 600, "wdth" 300;
    text-shadow: 1px 1px 0px #2385b9, 2px 2px 0px #2385b9, 3px 3px 0px #2385b9;
    @media(max-width: 600px) {
      font-size: 35px;
      text-shadow: 1px 1px 0px #2385b9, 2px 2px 0px #2385b9;
    }
  }
  &__lead {
    max-width: 600px;
    margin: 15px auto 0;
    padding: 0 30px;
    text-align: center;
    // color: #EC4452;
    font-size: 20px;
    font-variation-settings: "wght" 300, "wdth" 0;
    color: #333;
    line-height: 1.5em;
  }
}

.partners {
  background-color: #fff;
  padding: 50px 0;

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 30px 0;
    a {
      display: block;
      margin: 30px 60px;
    }

    @media(max-width: 600px) {
      flex-direction: column;
    }
  }

  img {
    flex-shrink: 1;
    // width: 100%;
    // max-width: 230px;
    max-height: 120px;
    max-width: 200px;
    // margin: 60px 30px;
    // margin: 30px;
    // filter: grayscale(100%);

    &:hover {
      opacity: .7;
    }
  }
}
.footer {
  padding: 30px 10px;
  text-align: center;
  font-variation-settings: "wght" 300, "wdth" 0;
  font-size: 14px;
  color: #999;

  &__links {
    padding: 15px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    a {
      display: block;
      color: #999;
      margin-right: 15px;
      margin-top: 10px;
      margin-bottom: 10px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.donate {
  margin-top: 60px;
  // background-color: #fff;
  padding: 15px;

  &__lead {
    font-family: 'Normalidad Variable', sans-serif;
    font-variation-settings: "wght" 600, "wdth" 60;
    letter-spacing: 1px;
    margin-bottom: 20px;
    font-size: 12px;
    text-transform: uppercase;
    color: #444;
    scroll-margin: -50px 0 0 0;
  }
}

.donation-options {
  // background-color: #999;
  // border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;

  @media(max-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }

  &__input {
    grid-column-start: span 2;
    text-align: center;
    padding: 15px 20px;
    background-color: #e0e0e0;
    border: none;
    border-radius: 8px;
    font-family: 'Normalidad Variable', sans-serif;
    font-size: 16px;
    color: #666;
    font-variation-settings: "wght" 700, "wdth" 60;


    &::placeholder {
      font-family: 'Normalidad Variable', sans-serif;
      font-variation-settings: "wght" 400, "wdth" 60;
      font-size: 16px;
      color: #b0b0b0;
    }

    &:focus {
      outline: none;
      background-color: #fff;
      box-shadow: 0 0 0 2px #84cdfa;
    }
    &.active {
      background-color: #2385b9;
      background-color: #4facfe;
      // border-color: #2385b9;
      color: #fff;
      box-shadow: #84cdfa 0px 4px 12px;
    }
  }

  &__option {
    padding: 15px 20px;
    border: none;
    border-radius: 8px;
    background-color: #e0e0e0;
    font-family: 'Normalidad Variable', sans-serif;
    font-size: 16px;
    color: #666;
    font-variation-settings: "wght" 700, "wdth" 60;
    cursor: pointer;

    &:hover {
      color: #444;
      background-color: #fff;
    }

    // &:first-of-type {
    //   border-radius: 6px 0 0 0;
    // }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5) inset;
      border-color: transparent;
    }

    &.active {
      background-color: #2385b9;
      background-color: #4facfe;
      // border-color: #2385b9;
      color: #fff;
      box-shadow: #84cdfa 0px 4px 12px;
    }
  }
}

.buy-button {
  margin-top: 15px;
  cursor: pointer;
  background-color: #EC4452;
  border: 1px solid #EC4452;
  color: #fff;
  padding: 10px 60px 12px;
  border-radius: 6px;
  width: 100%;
  box-sizing: border-box;

  font-family: 'Normalidad Variable', sans-serif;
  font-size: 18px;
  color: #fff;
  font-weight: normal;
  font-variation-settings: "wght" 500, "wdth" 0;

  span {
    font-variation-settings: "wght" 400, "wdth" 0;
    margin-top: 5px;
    letter-spacing: .3px;
    font-size: 12px;
    display: block;
    opacity: .6;
  }

  &--disabled {
    background-color: #666;
    border-color: #666;
    color: #ccc;
  }

  &:focus {
    outline: none;
  }
  &:focus-visible {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }
}

.buy-input {
  border: none;
  min-width: 250px;
  background-color: transparent;
  width: 100%;
  padding: 10px 15px;
  font-family: 'Normalidad Variable', sans-serif;
  font-size: 18px;
  font-variation-settings: "wght" 400, "wdth" 100;
  border-radius: 8px;
  color: #333;
  
  &:focus {
    outline: none;
  }
  
  
  &::placeholder {
    color: #d0d0d0;
  }
  
  
  &__label {
    padding-left: 15px;
    text-align: left;
    display: block;
    color: #666;
    font-family: 'Normalidad Variable', sans-serif;
    font-variation-settings: "wght" 600, "wdth" 60;
    letter-spacing: 1px;
    font-size: 12px;
    text-transform: uppercase;
    color: #666;

    span {
      color: #999;
    }

    &.error {
      color: #ec4452;
      opacity: 1;
    }
  }
  &__wrapper {
    // box-shadow: 0 0 0 1px rgba(0,0,0, 0.1);
    margin-top: 30px;
    margin-bottom: 10px;
    transition: all .2s ease-in-out;
    border-bottom: 2px solid #e0e0e0;

    &:focus-within {
      border-bottom: 2px solid #4facfe;

      label {
        color: #2385b9;
      }
    }
    &.error {
      border-bottom: 2px solid #ec4452;
      label {
        color: #ec4452;
      }
    }
  }
}

.about-strip {
  background-color: #E4405F;
  color: #fff;
  height: 30px;

  background-image: url(/images/bg/red.jpg);
  background-size: 20%;
}

.about-heading {
  color: #EC4452;
  text-align: center;
  // color: #EC4452;
  font-size: 50px;
  font-variation-settings: "wght" 600, "wdth" 300;
  text-shadow: 1px 1px 0px #2385b9, 2px 2px 0px #2385b9, 3px 3px 0px #2385b9;
  position: relative;
  z-index: 3;
  margin-bottom: 40px;
  @media(max-width: 600px) {
    font-size: 35px;
    margin-bottom: 40px;
    text-shadow: 1px 1px 0px #2385b9, 2px 2px 0px #2385b9;
  }
}

.about-text {
  max-width: 1100px;
  padding: 30px;
  margin: 0 auto;

  p {
    font-variation-settings: "wght" 200, "wdth" 30;
    letter-spacing: -.25px;
    font-size: 22px;
    color: var(--gray-600);
    line-height: 1.6em;
    margin: 0 auto 1.5em;
    max-width: 650px;
    color: #222;

  }
  @media(max-width: 600px) {
    padding: 30px 0;
    p {
      font-size: 18px;
      padding: 0 30px;
    }
  }
}

.instagram-link {
  display: inline-flex;
  align-items: center;
  position: relative;
  line-height: 22px;
  // margin-left: 5px;
  // margin-right: 5px;
  margin: 0 5px;
  text-decoration: none;
  // font-variation-settings: "wght" 400, "wdth" 60;
  color: #2385b9;
  transform: translateY(4px);

  &::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    background-color: #84ccfa;
    opacity: .15;
    z-index: -1;

    border-radius: 8px;
  }

  svg {
    width: 22px;
    margin-right: 7px;
    fill: #ef5b5f;
  }

  &:hover {
    color: #fff;
    &:before {
      opacity: 1;
      background-color: #E4405F;
    }

    svg {
      fill: #fff;
    }
  }
}

.about-authors {
  @media(max-width: 600px) {
    a {
      margin: 10px 5px;
    }
  }
}

.about-photo {
  max-width: 100%;
  margin: 30px 0;
}

.link-button {
  display: block;
  margin: 30px auto;
  text-align: center;
  border: 1px solid #000;
  max-width: 200px;
  text-decoration: none;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px;
  color: #333;

  &:hover {
    background-color:#E4405F;
    color: #fff;
    border-color: #E4405F;
  }
}

.link-donate-button {
  display: block;
  margin: 30px auto;
  text-align: center;
  max-width: 200px;
  text-decoration: none;
  border: 1px solid #E4405F;
  border-radius: 8px;
  background-color:#E4405F;
  color: #fff;
  padding: 10px;


  &:hover {
    opacity: .8;
  }
}

.additional-info {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 30px;

  color: #444;

  h3 {
    text-align: center;
  }

  .city {
    font-variation-settings: "wght" 700, "wdth" 60;
    color: #666;
    text-align: center;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-top: 30px;
  }

  ul {
    font-variation-settings: "wght" 300, "wdth" 60;
    font-size: 16px;
    list-style-type: none;
    margin: 0;
    padding: 15px 0;
  }
  li {
    margin-bottom: 10px;
    line-height: 1.5em;
    margin-bottom: 15px;
  }

  &.timetable {
    max-width: 500px;
  }

  & .time {
    font-variation-settings: "wght" 600, "wdth" 60;
    color: #ef5b5f;
  }
}
.additional-info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media(max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.video-container {
  width: 100%;
  max-width: 1000px;
  margin: 30px auto 60px;
}
.about-video {
  padding-top: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.event-link {
  // font-weight: bold;
  text-decoration: underline;
  color: #ef5b5f;

  &:hover {
    color: #b84448;
  }
}
.skip-link {
  text-align: center;
  display: block;
  margin: -1rem auto 2rem;
  color: #666;
}

.reviews {
  background-color: #f0f0f0;
  padding: 50px 0;
}

.reviews-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  padding: 0 30px;
  margin-top: 30px;

  @media(max-width: 1150px) {
    grid-template-columns: 1fr;
  }
}

.review {
  padding: 30px 40px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0.4px 0.8px 0.9px hsl(0deg 0% 72% / 0.36),
  1.6px 3px 3.5px -1.5px hsl(0deg 0% 72% / 0.31),
  6.4px 12.2px 14.4px -3px hsl(0deg 0% 72% / 0.27);

  @media(max-width: 1150px) {
    align-items: center;
    padding: 40px 30px;
  }

  &__text {
    font-variation-settings: "wght" 350, "wdth" 60;
    line-height: 1.5em;
    color: #444;
    max-width: 700px;
  }

  &__author {
    display: flex;
    align-items: center;
    padding-top: 30px;
    margin-top: auto;

    @media(max-width: 400px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  &__name {
    font-variation-settings: "wght" 550, "wdth" 100;
    color: #444;
  }

  &__desc {
    font-size: 14px;
    line-height: 1.3em;
    color: #666;
    margin-top: 5px;
    font-variation-settings: "wght" 300, "wdth" 60;
  }

  &__avatar {
    flex-shrink: 0;
    background-size: cover;
    background-position: center center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #999;
    margin-right: 20px;

    @media(max-width: 400px) {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
}

.modal {
  &__window {
    background-color: #fff;
    border-radius: 8px;
    max-width: 600px;
    padding: 70px 40px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  &__text {
    font-size: 18px;
    margin-bottom: 20px;
    font-variation-settings: "wght" 350, "wdth" 60;
    line-height: 1.5em;
    color: #444;
    max-width: 700px;
    text-align: center;
  }
  &__wrapper {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,.8);
  }

  &__close {
    padding: 20px;
    position: absolute;
    top:0;
    right: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;

    svg {
      width: 40px;
      height: 40px;
      color: #999;
    }

    &:hover {
      svg {
        color: #666;
      }
    }
  }
}